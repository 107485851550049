import { api } from './api';
import { Event } from '@/types/event';

const eventsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllEvents: builder.query<{ events: Event[] }, void>({
      query: () => `/events`,
    }),
    getEventById: builder.query<{ event: Event }, { eventId?: Event['id'] }>({
      providesTags: ['Event'],
      query: ({ eventId }) => `/events/${eventId}`,
    }),
  }),
  overrideExisting: false,
});

export const userEndpoints = eventsApi.endpoints;
export const { useGetAllEventsQuery, useGetEventByIdQuery } = eventsApi;
