import { useGetAllEventsQuery } from '@/api/event';
import calendar from '@/assets/calendar.svg';
import location from '@/assets/location.svg';
import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet';
import { eventList } from '@/constants/eventList';
import clsx from 'clsx';
import { Edit } from 'lucide-react';
import { Link, useParams } from 'react-router-dom';

export const EditEvent = () => {
  const { eventId } = useParams();
  const { data } = useGetAllEventsQuery();
  const events = data?.events ?? [];

  const getDateInProperFormat = (date: Date) => {
    date = new Date(date);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  };

  return (
    <Sheet>
      <SheetTrigger className="flex justify-center items-center gap-x-2 w-full h-8 my-1.5 bg-slate-200 border border-slate-300 rounded-md">
        <Edit className="size-3.5" />
        <p className="text-xs font-medium text-slate-900">Zmień wydarzenie</p>
      </SheetTrigger>
      <SheetContent className="pt-16 w-full" side="left">
        <p className="font-medium leading-snug text-slate-900 pb-4 text-center">
          Zmień wydarzenie
        </p>
        <div className="mt-3 mb-4 flex flex-col gap-y-3">
          {events.map(({ id, name, from, to }, index) => (
            <Link
              to={`/wydarzenie/${id}`}
              key={index}
              className={clsx(
                'flex flex-col gap-y-2 bg-slate-200 border border-slate-300 hover:duration-300 hover:bg-slate-300 hover:border-slate-600 p-4 rounded-md',
                String(eventId) === String(id) &&
                  'bg-slate-300 border-slate-600',
              )}
            >
              <p className="text-slate-900 text-[13px] font-medium leading-[18.20px]">
                {name}
              </p>
              <div className="flex items-center gap-x-5">
                {/* <div className="flex items-center gap-x-2">
                  <img
                    src={location}
                    alt="Location icon"
                    className="size-3.5"
                  />
                  <p className="text-xs text-slate-600 leading-tight">{city}</p>
                </div> */}
                <div className="flex items-center gap-x-2">
                  <img src={calendar} alt="Calendar icon" />
                  <p className="text-xs text-slate-600 leading-tight">
                    {getDateInProperFormat(from)} - {getDateInProperFormat(to)}
                  </p>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </SheetContent>
    </Sheet>
  );
};
