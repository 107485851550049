import clsx from 'clsx';

export const ScannerBorders = () => {
  const borderStyleHorizontal = 'w-[73px] h-[6px] bg-amber-400';
  const borderStyleVertical = 'w-[6px] h-[73px] bg-amber-400';
  return (
    <div className="flex flex-col">
      <div className="flex justify-between z-10">
        <span className={clsx(borderStyleHorizontal, 'rounded-r-full')} />
        <span className={clsx(borderStyleHorizontal, 'rounded-l-full')} />
      </div>
      <div className="flex">
        <div className="flex flex-col justify-between z-10">
          <span className={clsx(borderStyleVertical, 'rounded-b-full')} />
          <span className={clsx(borderStyleVertical, 'rounded-t-full')} />
        </div>
        <div className="size-68 shadow-black-background" />
        <div className="flex flex-col justify-between z-10">
          <span className={clsx(borderStyleVertical, 'rounded-b-full')} />
          <span className={clsx(borderStyleVertical, 'rounded-t-full')} />
        </div>
      </div>
      <div className="flex justify-between z-10">
        <span className={clsx(borderStyleHorizontal, 'rounded-r-full')} />
        <span className={clsx(borderStyleHorizontal, 'rounded-l-full')} />
      </div>
    </div>
  );
};
