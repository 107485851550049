import { createBrowserRouter } from 'react-router-dom';
import { App } from './App';
import { Event } from './components/event/Event';
import { PickEvent } from './components/PickEvent';
import { Login } from './components/login/Login';
import { Scanner } from './components/scan/Scanner';

export const router = createBrowserRouter([
  {
    path: '/*',
    element: <App />,
    children: [
      {
        path: 'wydarzenia',
        element: <PickEvent />,
      },
      {
        path: 'wydarzenie/:eventId',
        element: <Event />,
      },
      {
        path: 'skaner',
        element: <Scanner />,
      },
    ],
  },
  {
    path: 'login',
    element: <Login />,
  },
]);
