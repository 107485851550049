import { TicketCheck } from '@/types/ticket-check';
import { Axios } from 'axios';
import Cookies from 'js-cookie';

export const checkTicketEntry = async (
  eventId: string,
  generatedTicketId: string,
  mode: string,
): Promise<TicketCheck> => {
  const accessToken = Cookies.get('access_token');

  const axios = new Axios({
    baseURL: import.meta.env.VITE_API_URL,
    headers: { Authorization: `Bearer ${accessToken}` },
  });

  try {
    const response = await axios.post<string>(
      `/ticket-check/${eventId}/${generatedTicketId}?mode=${mode}`,
    );

    const result: { ticketCheck: TicketCheck } = JSON.parse(response.data);
    return result.ticketCheck;
  } catch (e) {
    console.error('error', e);
    return { valid: false, message: 'Błąd serwera' };
  }
};
