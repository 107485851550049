import { Event } from '@/types/event';
import { api } from './api';
import { Pass } from '@/types/pass';

const ordersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPassesByEmail: builder.query<
      { passes: Pass[]; count: number },
      {
        eventId?: Event['id'];
        email: string;
      }
    >({
      providesTags: ['Pass'],
      query: ({ email }) => `/passes/by-email/${email}`,
    }),
    togglePassActivity: builder.mutation<
      { pass: Pass },
      { passId: Pass['id'] }
    >({
      invalidatesTags: ['Pass'],
      query: ({ passId }) => ({
        url: `/passes/${passId}/toggle-activity`,
        method: 'PATCH',
      }),
    }),
  }),

  overrideExisting: false,
});

export const eventsApiEndpoints = ordersApi.endpoints;
export const { useGetPassesByEmailQuery, useTogglePassActivityMutation } =
  ordersApi;
