import logOut from '@/assets/logOut.svg';
import { Button } from '@/components/ui/button';
import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet';
import { useLogout } from '@/hooks/useLogout';
import { useCurrentUser } from '@/slices/credentials/selectors';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import { AvatarElement } from './AvatarElement';

export const MenuUser = (): ReactElement => {
  const user = useCurrentUser();
  const logout = useLogout();
  const location = useLocation();

  return (
    <>
      {location.pathname === '/skaner' ? null : (
        <Sheet>
          <SheetTrigger>
            <div className="flex items-center gap-x-2 mb-4">
              <AvatarElement />
              <div className="flex flex-col pl-4">
                <ChevronUp className="w-3 h-3" />
                <ChevronDown className="w-3 h-3" />
              </div>
            </div>
          </SheetTrigger>
          <SheetContent
            className="flex flex-col justify-between p-0 rounded-tr-2xl rounded-br-2xl"
            side="left"
          >
            <div>
              <div className="flex items-center gap-x-2 pt-12 px-5 pb-4 border-b border-slate-200">
                <AvatarElement />
              </div>
              <div className="flex flex-col gap-y-3 px-5 py-4">
                <div className="flex flex-col">
                  <p className="text-slate-500 text-[11px] font-medium leading-tight">
                    Adres e-mail
                  </p>
                  <p className="text-slate-900 text-xs leading-tight">
                    {user?.email}
                  </p>
                </div>
                <br />
                <br />

                <div className="flex flex-col">
                  <p className="text-[11px] text-red-700 font-medium leading-tight text-xl mb-2">
                    Pomoc
                  </p>
                  <p className="text-slate-900 text-xs leading-tight">
                    W razie problemów z aplikacją skontaktuj się z
                    <br />
                    <b>
                      Iwo Dindas - <a href="tel:881476494">881476494</a>
                    </b>
                    <br />
                    <b>
                      Mateusz Żupa - <a href="tel:605335546">605335546</a>
                    </b>
                  </p>
                </div>
                {/* <div className="flex flex-col">
                  <p className="text-slate-500 text-[11px] font-medium leading-tight">
                    Telefon
                  </p>
                  <p className="text-slate-900 text-xs leading-tight">
                    // TODO Add phone number -> user?.phoneNumber
                  </p>
                </div> */}
              </div>
            </div>
            <div>
              <Button
                variant="ghost"
                onClick={logout}
                className="text-black text-sm font-medium leading-tight flex gap-x-2 justify-start px-5 py-3 items-center w-full"
              >
                <img src={logOut} alt="Logout icon" />
                <p>Wyloguj</p>
              </Button>
              <div className="px-5 py-3 flex justify-end w-full ">
                <p className="text-[#64748B] text-[11px] leading-tight">
                  v 1.01
                </p>
              </div>
            </div>
          </SheetContent>
        </Sheet>
      )}
    </>
  );
};
