import { useGetAllEventsQuery } from '@/api/event';
import calendar from '@/assets/calendar.svg';
import { Link } from 'react-router-dom';

export const PickEvent = () => {
  const { data } = useGetAllEventsQuery();
  const events = data?.events ?? [];

  const getDateInProperFormat = (date: Date) => {
    date = new Date(date);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  };

  return (
    <div>
      <div className="flex flex-col gap-y-2 mb-6">
        <p className="text-slate-900 leading-snug font-semibold">
          Zanim rozpoczniesz...
        </p>
        <p className="text-slate-700 text-xs leading-tight">
          Wybierz wydarzenie, w którym jesteś odpowiedzialny za weryfikację
          biletów
        </p>
      </div>
      <div className="mt-3 mb-4 flex flex-col gap-y-3">
        {events.map(({ id, name, from, to }, index) => (
          <Link
            to={`/wydarzenie/${id}`}
            key={index}
            className="flex flex-col gap-y-2 bg-slate-200 border border-slate-300 hover:duration-300 hover:bg-slate-300 hover:border-slate-600 p-4 rounded-md"
          >
            <p className="text-slate-900 text-[13px] font-medium leading-[18.20px]">
              {name}
            </p>
            <div className="flex items-center gap-x-5">
              {/* <div className="flex items-center gap-x-2">
                <img src={location} alt="Location icon" className="size-3.5" />
                <p className="text-xs text-slate-600 leading-tight">{city}</p>
              </div> */}
              <div className="flex items-center gap-x-2">
                <img src={calendar} alt="Calendar icon" />
                <p className="text-xs text-slate-600 leading-tight">
                  {getDateInProperFormat(from)} - {getDateInProperFormat(to)}
                </p>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};
