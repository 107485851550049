import { trimId } from '@/utils/trimId';
import { ColumnDef } from '@tanstack/react-table';
import { File } from 'lucide-react';
import { ShortenStringClipboard } from '../ShortenStringClipboard';
import { Pass } from '@/types/pass';
import { Button } from '../ui/button';
import { Link } from 'react-router-dom';
import { Switch } from '../ui/switch';
import { useState } from 'react';
import { ConfirmationModal } from '../ConfirmationModal';
import { useTogglePassActivityMutation } from '@/api/pass';

export const columns: ColumnDef<Pass>[] = [
  {
    accessorKey: 'createdAt',
    header: 'Wykorzystano',
    cell: ({ row }) =>
      !!row.original.ticketChecks.length
        ? new Date(row.original.createdAt).toLocaleDateString() +
          ' ' +
          new Date(row.original.createdAt).toLocaleTimeString()
        : 'Nie',
  },
  {
    accessorKey: 'isActive',
    header: 'Aktywny',
    cell: ({ row }) => {
      return <p>{!!row?.original?.isActive ? 'Aktywny' : 'Dezaktywowany'}</p>;
      // const [active, setActive] = useState(!!row?.original?.isActive);
      // const [toggleActivity] = useTogglePassActivityMutation();
      // return (
      //   <ConfirmationModal
      //     type="delete"
      //     title="Czy na pewno chcesz dezaktywować ten bilet?"
      //     description="Ta operacja uniemoliwi kupującemu wstępn na wydarzenie."
      //     onConfirm={() => toggleActivity({ passId: row.original.id })}
      //   >
      //     <Switch
      //       checked={active}
      //       onClick={() => setActive(!active)}
      //       id="action"
      //     />
      //   </ConfirmationModal>
      // );
    },
  },
  {
    accessorKey: 'id',
    header: 'ID',
    cell: ({ row }) => (
      <ShortenStringClipboard
        content={row.original.id}
        argName="ID"
        trimCallback={trimId}
      />
    ),
  },
  {
    accessorKey: 'source',
    header: 'Źródło',
    cell: ({ row }) => row.original.source,
  },
  {
    accessorKey: 'ticketsFile',
    header: 'Dokument PDF',
    cell: ({ row }) =>
      !!row?.original?.ticketsFile ? (
        <Button
          asChild
          variant="secondary"
          className="text-sm font-medium bg-slate-100 rounded-md border border-slate-300 justify-center items-center"
        >
          <Link
            target="_blank"
            to={`https://storage.googleapis.com/dramoff-tickets/${row.original.ticketsFile}`}
          >
            <File className="w-4 h-4 mr-1" />
            <p className="text-sm font-medium leading-normal">
              Zobacz dokument PDF
            </p>
          </Link>
        </Button>
      ) : (
        'Brak'
      ),
  },
];
