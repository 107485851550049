import clsx from 'clsx';
import { ReactElement, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ProtectedRoute } from './ProtectedRoute';
import { useGetCurrentUserQuery } from './api/users';
import { MenuUser } from './components/MenuUser';
import { useIsUserLoggedIn } from './slices/credentials/selectors';
import occurra from '@/assets/logo.webp';

export function App(): ReactElement {
  const isLoggedIn = useIsUserLoggedIn();
  const navigate = useNavigate();
  const location = useLocation();
  useGetCurrentUserQuery(undefined, { skip: !isLoggedIn });

  useEffect(() => {
    isLoggedIn && navigate('/wydarzenia');
  }, []);

  return (
    <ProtectedRoute>
      <div className="flex justify-center">
        <div
          className={clsx(
            'flex flex-col h-[100dvh] w-screen items-center max-w-[448px]',
            location.pathname === '/skaner'
              ? 'bg-black/10 h-screen'
              : 'bg-slate-50 ',
          )}
        >
          <div
            className={clsx(
              'max-w-120 w-full',
              location.pathname !== '/skaner' && 'pt-12 px-4',
            )}
          >
            <div className="flex justify-between items-start mt-[-1rem]">
              <img src={occurra} className="w-32 h-auto mt-[-4px]" />
              <MenuUser />
            </div>
            <Outlet />
          </div>
        </div>
      </div>
    </ProtectedRoute>
  );
}
