import blueCheck from '@/assets/blue-check.svg';
import { Sheet, SheetContent } from '@/components/ui/sheet';

type Props = {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  message: string;
  title?: string;
};

export const ScannerFeedback = ({
  isModalOpen,
  title,
  message,
  setIsModalOpen,
}: Props) => {
  return (
    <Sheet open={isModalOpen}>
      <SheetContent
        className="space-y-16 rounded-t-2xl pt-6 px-4 pb-1.5"
        side="bottom"
        hideCloseButton
      >
        <div className="flex flex-col gap-y-4">
          {!title && (
            <img src={blueCheck} alt="Blue check" className="mx-auto" />
          )}
          {!!title && (
            <p className="text-lg font-bold text-center leading-[25.20px]">
              {title}
            </p>
          )}
          <p className="text-slate-900 text-lg leading-[25.20px] text-center">
            {message}
          </p>
        </div>
        <button
          onClick={() => setIsModalOpen(false)}
          className="text-slate-900 text-xs font-semibold leading-tight text-center w-full px-5 py-3"
        >
          Wróć do skanowania
        </button>
      </SheetContent>
    </Sheet>
  );
};
