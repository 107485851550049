import google from '@/assets/google.svg';
import userEllipse from '@/assets/user-ellipse.svg';
import { useIsUserLoggedIn } from '@/slices/credentials/selectors';
import { useAuth0 } from '@auth0/auth0-react';
import { ReactElement, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import { Alert, AlertDescription, AlertTitle } from '../ui/alert';
// import { AlertCircle } from 'lucide-react';
import { Button } from '../ui/button';
import logo from '@/assets/logo.webp';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '../ui/card';

export const Login = (): ReactElement => {
  const { logout, loginWithRedirect } = useAuth0();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isUserLoggedIn = useIsUserLoggedIn();
  if (isUserLoggedIn) navigate('/wydarzenia');

  const handleOAuthLogin = useCallback(async () => {
    try {
      await loginWithRedirect();
    } catch (error: any) {
      console.error('Auth0 login error', error);
      await logout();
    }
  }, [dispatch, loginWithRedirect, logout]);

  return (
    <section className="flex flex-col justify-center sm:justify-center items-center h-screen w-screen">
      <div className="flex flex-col gap-y-4 px-4">
        {/* <Alert variant="destructive" className="bg-red-100 border-none">
          <AlertCircle className="h-4 w-4 text-red-700 stroke-red-700" />
          <AlertTitle className="text-red-700 text-sm font-medium leading-tight mb-1">
            Twoje konto nie zostało znalezione
          </AlertTitle>
          <AlertDescription className="text-red-700 text-xs leading-none">
            Spróbuj zalogować się na inne konto, lub skontaktuj się z supportem
          </AlertDescription>
        </Alert> */}
        <Card className="flex flex-col items-center max-w-120 pt-10 pb-16 border-none shadow-none bg-white rounded-t-[22px] rounded-b-none">
          <img src={logo} className="mx-auto pt-6 w-64" />

          <CardHeader className="px-0">
            <CardTitle className="text-[22px] font-semibold leading-9 text-center">
              Zaloguj się
            </CardTitle>
            <CardDescription className="text-[#64748B] text-xs leading-tight">
              Aby mieć dostęp do aplikacji wystarczy, że zalogujesz się za
              pomocą konta Google
            </CardDescription>
          </CardHeader>
          <CardContent className="px-0 w-full">
            <Button
              onClick={handleOAuthLogin}
              className="flex items-center gap-x-2 w-full px-8 py-3"
            >
              <img src={google} />
              <p className="text-xs font-medium">
                Zaloguj się za pomocą Google
              </p>
            </Button>
          </CardContent>
        </Card>
      </div>
      <div className="p-6 flex justify-end w-full max-w-120 bg-white">
        <p className="text-[#64748B] text-[11px] leading-tight">v 1.01</p>
      </div>
    </section>
  );
};
