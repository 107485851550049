import { checkTicketEntry } from '@/api/ticket-check';
import blackQr from '@/assets/black-qr.svg';
import chevronleft from '@/assets/chevron-left.svg';
import grayEllipse from '@/assets/gray-ellipse.svg';
import grayQr from '@/assets/gray-qr.svg';
import yellowEllipse from '@/assets/yellow-ellipse.svg';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { QrReader } from 'react-qr-reader';
import { Link, useSearchParams } from 'react-router-dom';
import { ScannerFeedback } from './ScannerFeedback';
import { ScannerBorders } from './ScannerBorders';
import { throttle } from 'lodash';

export const Scanner = () => {
  const [isScanning, setIsScanning] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState<string>('');
  const [feedbackTitle, setFeedbackTitle] = useState<string>('');
  const [qrData, setQRData] = useState<QRData | null>(null);
  const [searchParams] = useSearchParams();

  const eventId = searchParams.get('eventId') ?? '';
  const mode = searchParams.get('mode') ?? '';

  type QRData = {
    generatedTicketId: string;
    eventName: string;
    startDate: string;
    endDate: string;
  };

  const checkTicket = throttle(async (qrData: QRData) => {
    try {
      const ticketCheck = await checkTicketEntry(
        eventId!,
        qrData.generatedTicketId,
        mode,
      );

      if (!!ticketCheck?.valid) {
        setFeedbackMessage('Bilet został pomyślnie zweryfikowany');
      } else {
        setFeedbackTitle('Ważność biletu!');
        setFeedbackMessage(ticketCheck.message);
      }
    } catch (error) {
      setFeedbackTitle('Wystapił błąd');
      setFeedbackMessage('Błąd podczas przetwarzania danych QR');
      console.error('Błąd podczas przetwarzania danych QR:', error);
    } finally {
      setQRData(null);
    }
  }, 1000);

  const handleResult = (result: any) => {
    setIsScanning(!!result);
    if (!result) return;

    const rawQrData = result.getText();
    const qrData = JSON.parse(rawQrData) as QRData;
    setQRData(qrData);
  };

  useEffect(() => {
    if (qrData && !isModalOpen) {
      setIsScanning(false);
      setIsModalOpen(true);
      checkTicket(qrData);
    }
  }, [qrData]);

  useEffect(() => {
    !isModalOpen && setQRData(null);
  }, [isModalOpen]);

  return (
    <div className="h-screen">
      <>
        <div className="pt-12.5">
          <QrReader
            onResult={handleResult}
            scanDelay={500}
            constraints={{
              facingMode: 'environment',
            }}
            videoStyle={{
              width: '100vw',
              minHeight: '100vh',
              maxWidth: '480px',
              objectFit: 'cover',
              zIndex: -20,
            }}
            videoContainerStyle={{
              width: '100vw',
              minHeight: '100vh',
              zIndex: -20,
            }}
            className="absolute left-0 sm:left-auto top-0 -z-20"
          />
          <Link
            to={`/wydarzenie/${eventId}?mode=${mode}`}
            className="flex gap-x-1 items-center p-2.5 w-[78px]"
          >
            <img src={chevronleft} alt="Chevron left" />
            <p className="text-white text-xs font-normal leading-tight">Wróć</p>
          </Link>
        </div>
        <div className="flex flex-col items-center gap-y-9 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 -z-10">
          {isScanning ? (
            <ScannerBorders />
          ) : (
            <div className="size-68 shadow-black-background" />
          )}

          <p className="text-white text-xs leading-none max-w-68">
            Nakieruj kamerę na kod QR, tak aby znalazł się wewnątrz kwadratu
          </p>
        </div>
        <Link
          to="/skaner"
          className={clsx(
            'absolute left-1/2 -translate-x-1/2 z-10 bottom-10',
            isScanning && 'animate-pulse',
          )}
        >
          <div className="relative flex justify-center items-center size-16 mx-auto">
            <img
              src={isScanning ? yellowEllipse : grayEllipse}
              alt={isScanning ? 'Yellow ellipse' : 'Gray ellipse'}
              className={clsx(isScanning && 'scale-125')}
            />
            <img
              src={isScanning ? blackQr : grayQr}
              alt="QR code"
              className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
            />
          </div>
          {/* {isReadyForScan && (
              <p className="text-amber-400 text-xs font-medium leading-none px-4 pt-3 pb-4">
                Skanuj
              </p>
            )} */}
        </Link>
      </>

      <ScannerFeedback
        title={feedbackTitle}
        message={feedbackMessage}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </div>
  );
};
