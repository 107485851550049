import blackEllipse from '@/assets/black-ellipse.svg';
import calendar from '@/assets/calendar.svg';
import whiteQr from '@/assets/white-qr.svg';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { EditEvent } from '../EditEvent';
import { useGetEventByIdQuery } from '@/api/event';
import { Switch } from '../ui/switch';
import { useEffect, useState } from 'react';
import { Loading } from '../login/Loading';
import { Input } from '../ui/input';
import { DataTable } from './DataTable';
import { columns } from './columns';
import { useGetPassesByEmailQuery } from '@/api/pass';

const MASTERCLASS_MODE = 'masterclass';

export const Event = () => {
  const { eventId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isMasterclass, setMasterclass] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');

  const { data: passesData } = useGetPassesByEmailQuery(
    { email },
    { skip: !email },
  );

  const passes = passesData?.passes ?? [];

  useEffect(() => {
    const mode = searchParams.get('mode');
    setMasterclass(mode === MASTERCLASS_MODE);
  }, [searchParams]);

  const toggleMasterclassMode = () => {
    setSearchParams((prev) => {
      prev.set('mode', isMasterclass ? '' : MASTERCLASS_MODE);
      return prev;
    });
  };

  const { data } = useGetEventByIdQuery(
    { eventId: eventId! },
    { skip: !eventId },
  );

  const getDateInProperFormat = (date: Date) => {
    date = new Date(date);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  };

  const event = data?.event;

  return event ? (
    <div>
      <div className="flex flex-col bg-white px-4 pt-4 pb-2.5 rounded-[22px] w-full">
        <div className="flex justify-between w-full">
          <div>
            <p className="text-slate-500 text-[11px] leading-tight">
              Wydarzenie
            </p>
            <p className="text-slate-900 text-[13px] font-medium leading-snug mt-0.5 mb-4 font">
              {event?.name}
            </p>
          </div>
          <div className="flex flex-col justify-center items-end">
            <p className="text-xs">Skanuj degustacje</p>
            <Switch
              checked={isMasterclass}
              onClick={() => toggleMasterclassMode()}
            />
          </div>
        </div>
        <div className="flex flex-col gap-y-2 mb-4">
          {/* <div className="flex items-center gap-x-2">
              <img src={location} alt="Location icon" className="size-3.5" />
              <p className="text-xs text-slate-600 leading-tight">
                {event?.city}
              </p>
            </div> */}
          <div className="flex items-center gap-x-2">
            <img src={calendar} alt="Calendar icon" />
            <p className="text-xs text-slate-600 leading-tight">
              {getDateInProperFormat(event?.from)} -{' '}
              {getDateInProperFormat(event?.to)}
            </p>
          </div>
        </div>
        <EditEvent />
      </div>
      <div className="flex flex-col bg-white px-4 pt-4 pb-2.5 rounded-[22px] w-full mt-2">
        <div className="flex justify-between w-full flex-col gap-2">
          <p className="text-slate-500 text-[11px] leading-tight">
            Znajdź bilety dla adresu email
          </p>
          <Input
            type="text"
            placeholder="Email"
            value={email}
            onChange={({ target }) => setEmail(target.value)}
          />
          {!!passes?.length && (
            <div>
              <DataTable columns={columns} data={passes} />
            </div>
          )}
        </div>
      </div>
      <Link
        to={`/skaner?eventId=${event.id}&mode=${searchParams.get('mode') ?? ''}`}
        className="absolute left-1/2 -translate-x-1/2 bottom-0"
      >
        <div className="relative flex justify-center items-center size-16 mx-auto">
          <img src={blackEllipse} alt="Black ellipse" />
          <img
            src={whiteQr}
            alt="QR code"
            className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
          />
        </div>
        <p className="text-slate-800 text-xs font-medium leading-none px-4 pt-3 pb-4">
          Skanuj bilety
        </p>
      </Link>
    </div>
  ) : (
    <p></p>
  );
};
